<template>
  <data-list-component
    :module="logStoreModule"
    :use-list="logDataList"
    module-name="app-logs"
  />
</template>

<script>
import DataListComponent from './DataListComponent.vue'
import logDataList from './logDataList'
import logStoreModule from './logStoreModule'

export default {
  components: {
    DataListComponent,
  },
  data() {
    return {
      logDataList,
      logStoreModule,
    }
  },
}
</script>

<style></style>
