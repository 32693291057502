<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        ref="refDataListTable"
        class="position-relative table-data has-filter-thead"
        :items="fetchDataList"
        responsive
        hover
        striped
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :sticky-header="true"
      >
        <!-- Filters -->
        <template #thead-top="data">
          <b-tr class="filter-thead">
            <b-th
              v-for="field in data.fields"
              :key="field.key"
            >
              <b-form-input
                v-if="field.filter"
                v-model="filterInputs[field.key]"
                class="w-full m-0"
                :placeholder="'' + field.label"
              />
            </b-th>
            <!-- <span>{{data.fields}}</span> -->
          </b-tr>
        </template>

        <!-- Column: Roles -->
        <template #cell(roles)="data">
          {{ resolveRolesLabel(data.item.roles) }}
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveUserStatusLabel(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="$can('update', 'users')"
              @click="openSideBardFor('update', data.item)"
            >
              <feather-icon
                icon="EditIcon"
                class="inline"
              />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="flex flex-col mb-1 mb-md-0"
          >
            <span
              class="mb-1"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
            <div>
              <label>Show</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSidebar,
  BTr,
  BTh,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BRow,
    BTr,
    BTh,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSidebar,

    vSelect,
  },
  props: {
    module: {
      type: Object,
      required: true,
    },
    useList: {
      type: Function,
      required: true,
    },
    moduleName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const STORE_MODULE_NAME = props.moduleName
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, props.module)
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      fetchDataList,
      tableColumns,
      perPage,
      currentPage,
      totalRows,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      filterInputs,

      refetchData,

      resolveRolesLabel,
      resolveUserStatusLabel,
      resolveUserStatusVariant,

      basicFilter,

      addBtnText,
    } = props.useList()

    return {
      fetchDataList,
      tableColumns,
      perPage,
      currentPage,
      totalRows,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      filterInputs,

      refetchData,

      resolveRolesLabel,
      resolveUserStatusLabel,
      resolveUserStatusVariant,

      basicFilter,

      addBtnText,
    }
  },
}
</script>
